import * as React from "react"
import "../style/style.css"
import Menu from "../components/menu.js"
import Story from "../components/story"
import MenuSpacer from "../components/menuSpacer"
import story1 from "../images/Story1.jpg"

const StorieswallPage = () => {
  return (
    <main>
      <Menu></Menu>
      <MenuSpacer />
      
      <Story img={story1} />
    </main>
  )
}

export default StorieswallPage
