import * as React from "react"
import "../style/story.css"

const Story = (props) => {
  return (
    <div className="story row center">
      <img src={props.img} />
    </div>
  )
}

export default Story
